/* eslint-disable react-hooks/exhaustive-deps */
import { List, Select } from "antd";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { NAME_AUTOTICK_SELECT, textNoData } from "Utils/data-default";
import {
  convertArrChildSubjectToObject,
  convertAutotickSubject,
} from "Utils/convertValue";
const ListWrapper = styled(List)`
  padding-bottom: 2rem;
  padding-top: 2rem;

  .ant-list {
    padding: 0 0 !important;
    width: 100%;
  }

  .ant-list-item {
    background-color: #dcefff;
    border: 1px solid #c9c9c9;
    border-block-end: none;
    padding: 0 0 !important ;
    min-height: 100% !important;
    @media only screen and (max-width: 1300px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      height: 100% !important;
    }
  }

  .ant-list-item:last-child {
    border-bottom: 1px solid #c9c9c9;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }
  .ant-list-item:first-child {
    border-top-right-radius: 8px;
    overflow: hidden;
  }
  .ant-list .ant-list-item {
    padding: 0 0 !important;
  }
  .min-h-52 {
    min-height: 52px;
  }
  .name-courses {
    min-width: 208px !important;
    height: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 0 0 10px;
    .input_name_subject {
      display: flex;
      align-items: center;
    }
    .child-subject {
      margin: 0 10px;
      width: 100px;
      .ant-select-clear{
        display: flex;
        justify-content: center;
        right: 20px;
      }
    }
    @media only screen and (max-width: 1300px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 576px) {
      padding: 0 10px;
      height: 100% !important;
      min-width: 140px !important;
    }
    span {
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 30px;
      margin-left: 14px;
    }
    input[type="checkbox"] {
      appearance: none;
      background-color: #fff;
      border-radius: 2px;
      display: inline-grid;
      place-content: center;
      width: 28px;
      height: 28px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 20px;
      height: 20px;
      transform: scale(0);
      transform-origin: bottom left;
      background-color: #000;
      clip-path: polygon(39% 82%, 90% 15%, 97% 21%, 40% 96%, 0 65%, 6% 57%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    input[type="checkbox"]:checked {
      background-color: #fff;
      border: 2px solid #ccc;
    }
  }
 
  .list-action,
  .list-action-child {
    width: 100%;
    padding-left: 1.8rem;
    font-weight: 500;
    font-size: 16px;
    border-left: 1px solid #c9c9c9;
    background-color: #fafafa;
    min-height: 52px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    &.min-h-208 {
      min-height: 208px;
      padding-left: 0;
    }
    .ant-list-items {
      .ant-list-item {
        padding-left: 1.8rem !important;
        .ant-list {
          width: 100%;
        }
      }
    }
    .more_child_subject {
      @media only screen and (max-width: 1400px) {
        min-height: 104px !important;
      }
      @media only screen and (max-width: 920px) {
        min-height: 156px !important;
        align-content: center;
      }
      @media only screen and (max-width: 700px) {
        min-height: 208px !important;
      }
    }
    .course-item {
      padding-right: 30px;
      display: flex;
      align-items: center;
      line-height: 52px;

      @media only screen and (max-width: 1600px) {
        padding-right: 10px;
      }
      @media only screen and (max-width: 576px) {
        padding-right: 5px;
      }
      /* display: flex;
      align-items: center; */
    }

    input[type="checkbox"] {
      appearance: none;
      background-color: #fff;
      border-radius: 2px;
      display: inline-grid;
      place-content: center;
      width: 28px;
      height: 28px;
      border: 2px solid #c9c9c9;
      border-radius: 5px;
    }

    input[type="checkbox"]::before {
      content: "";
      width: 20px;
      height: 20px;
      transform: scale(0);
      transform-origin: bottom left;
      background-color: #000;
      clip-path: polygon(39% 82%, 90% 15%, 97% 21%, 40% 96%, 0 65%, 6% 57%);
    }

    input[type="checkbox"]:checked::before {
      transform: scale(1);
    }
    input[type="checkbox"]:checked {
      background-color: #fff;
      border: 2px solid #ccc;
    }
  }
  .list-action-child {
    justify-content: start;
    border: none !important;
    padding-left: 1.8rem !important;
  }
  .list_sub_content{
    width: 100%;
  }
  .sub_content{
    justify-content: start;
    border: none !important;
    border-bottom: 1px solid #c9c9c9 !important;
    &:last-child {
      border-bottom: none !important;
    }
  }
  .list-select {
    /* margin-bottom: 4px; */
    border-left: 1px solid #c9c9c9;
    .select-content{
      border-bottom: 1px solid #c9c9c9 !important;
      &:last-child {
        border-bottom: none !important;
      }
      .select-action-child {
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 52px;
        min-height: 52px;
        display: flex;
        align-items: center;
        
        &.more_child_subject {
          @media only screen and (max-width: 1400px) {
            min-height: 104px;
          }
          @media only screen and (max-width: 920px) {
            min-height: 156px;
          }
          @media only screen and (max-width: 700px) {
            min-height: 208px;
          }
        }
      }
    }
    .ant-select-selector {
      padding: 0;
      .ant-select-selection-item {
        font-size: 14px;
        padding: 0;
        margin: 0;
        text-align: center;
        margin-left: -25px;
      }
    }
  }
`;

function ListCourses({
  dataSubject,
  loading,
  dataSource,
  setValue,
  setSelectedContent,
  selectedContent,
  indexMathChecked,
  setIndexMathChecked,
  isPersonal,
  checkSocialScience,
  validateCheckSocialScience,
}) {
  const [t] = useTranslation("student");
  const [requiredValues, setRequiredValue] = useState([]);
  const [valueSelectScience, setValueSelectScience] = useState([]);
  const [valueSelectSociety, setValueSelectSociety] = useState([]);
  const [keySelectScience, setKeySelectScience] = useState([]);
  const [keySelectSociety, setKeySelectSociety] = useState([]);
  const [selectedValues, setSelectedValues] = useState({
    society: [],
    science: [],
  });
  const {
    compulsorySubjects,
    currentStudentInformation,
    // listSubjectContentCommonFirst,
    // listSubjectContentPersonalFirst
  } = useSelector((state) => state.student);

  useEffect(() => {
    const contentKeyScience = selectedContent?.[NAME_AUTOTICK_SELECT.SCIENCE]
      ? Object.keys(selectedContent?.[NAME_AUTOTICK_SELECT.SCIENCE])
      : [];
    const contentKeySociety = selectedContent?.[NAME_AUTOTICK_SELECT.SOCIETY]
      ? Object.keys(selectedContent?.[NAME_AUTOTICK_SELECT.SOCIETY])
      : [];
    // const getAllKeyScience = dataSubject
    //   ? Object.keys(dataSubject?.[NAME_AUTOTICK_SELECT.SCIENCE])
    //   : [];
    // const getAllKeyScociety = dataSubject
    //   ? Object.keys(dataSubject?.[NAME_AUTOTICK_SELECT.SOCIETY])
    //   : [];
    if (Object.keys(compulsorySubjects).length > 0 && checkSocialScience ) {
      while (contentKeyScience.length < 4) {
        contentKeyScience.push(`empty ${contentKeyScience.length}`);
      }
      while (contentKeySociety.length < 4) {
        contentKeySociety.push(`empty ${contentKeySociety.length}`);
      }
    }

    // if (
    //   JSON.stringify(contentKeyScience) !== JSON.stringify(keySelectScience) ||
    //   JSON.stringify(contentKeySociety) !== JSON.stringify(keySelectSociety)
    // ) {
    //   const data = { ...selectedContent };
    //   if (!data.hasOwnProperty(NAME_AUTOTICK_SELECT.SOCIETY)) {
    //     data[NAME_AUTOTICK_SELECT.SOCIETY] = {};
    //   }

    //   contentKeySociety.forEach((key) => {
    //     if (!data[NAME_AUTOTICK_SELECT.SOCIETY].hasOwnProperty(key)) {
    //       data[NAME_AUTOTICK_SELECT.SOCIETY][key] = [];
    //     }
    //   });

    //   if (!data.hasOwnProperty(NAME_AUTOTICK_SELECT.SCIENCE)) {
    //     data[NAME_AUTOTICK_SELECT.SCIENCE] = {};
    //   }

    //   contentKeyScience.forEach((key) => {
    //     if (!data[NAME_AUTOTICK_SELECT.SCIENCE].hasOwnProperty(key)) {
    //       data[NAME_AUTOTICK_SELECT.SCIENCE][key] = [];
    //     }
    //   });
    //   setSelectedContent(data);
    // }
    setKeySelectScience(contentKeyScience);
    setKeySelectSociety(contentKeySociety);
    setSelectedValues({
      society: contentKeySociety,
      science: contentKeyScience,
    });
  }, [selectedContent]);
  useEffect(() => {});
  useEffect(() => {
    let selectScience = dataSource.find(
      (item) => item.name === NAME_AUTOTICK_SELECT.SCIENCE
    );
    let selectSociety = dataSource.find(
      (item) => item.name === NAME_AUTOTICK_SELECT.SOCIETY
    );

    let arraySelectScience = selectScience?.child.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    let arraySelectSociety = selectSociety?.child.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    setValueSelectScience(arraySelectScience);
    setValueSelectSociety(arraySelectSociety);
  }, [dataSource]);
  useEffect(() => {
    if (compulsorySubjects && dataSource.length) {
      if (_.isEmpty(currentStudentInformation?.selectedContent)) {
        if (isPersonal) {
          compulsorySubjects.length &&
            setSelectedContent(compulsorySubjects["specific_exam"]);
        } else {
          compulsorySubjects.length &&
            setSelectedContent(compulsorySubjects["general_exam"]);
        }
      } else {
        if (isPersonal) {
          setSelectedContent(
            convertAutotickSubject(
              currentStudentInformation?.selectedContent?.["二次・個別"]
            )
          );
        } else {
          setSelectedContent(
            convertAutotickSubject(
              currentStudentInformation?.selectedContent?.["共通テスト"]
            )
          );
        }
      }
    } else {
      setSelectedContent([]);
    }
  }, [compulsorySubjects, dataSource]);

  const handleSelectChange = (value, name, id) => {
    const updateKey =
      name === "society" ? [...keySelectSociety] : [...keySelectScience];
    updateKey[id] = value;
    let obj = Object.assign({}, selectedContent);
    const nameSubjectSelect =
      name === "society"
        ? NAME_AUTOTICK_SELECT.SOCIETY
        : NAME_AUTOTICK_SELECT.SCIENCE;
    const dataListSelect = selectedContent[nameSubjectSelect] || {};
    
    if (Object.keys(compulsorySubjects).length > 0 && checkSocialScience ) {
      while (Object.keys(dataListSelect).length < 4) {
        const key = `empty ${Object.keys(dataListSelect).length}`;
        dataListSelect[key] = [];
      }
    }
    if (updateKey.length > 0) {
      let getNameInvalid = Object.keys(dataListSelect).filter(
        (item) => !updateKey.includes(item)
      );
      dataListSelect[value] = [];
      getNameInvalid && delete dataListSelect[getNameInvalid];
    }
    if (name === "society") {
      setKeySelectSociety(updateKey);
    } else {
      setKeySelectScience(updateKey);
    }
    
    const sortedDataSelect = Object.fromEntries(
      Object.entries(dataListSelect).sort(
        ([a], [b]) => updateKey.indexOf(a) - updateKey.indexOf(b)
      )
    );
    obj = {
      ...obj,
      [nameSubjectSelect]: sortedDataSelect,
    };
    setSelectedValues((prevValues) => {
      return {
        ...prevValues,
        [name]: updateKey,
      };
    });
    setSelectedContent(obj);
  };

  const onChange = (value, name, id, list, nameChild) => {
    let obj = {};

    if (
      nameChild !== NAME_AUTOTICK_SELECT.SOCIETY &&
      nameChild !== NAME_AUTOTICK_SELECT.SCIENCE
    ) {
      let arr = selectedContent?.[name] || [];

      if (arr.includes(value)) {
        arr = arr.filter((n) => n !== value);
        setRequiredValue(requiredValues.filter((it) => it !== value));
      } else {
        arr = [...arr, value];
        setRequiredValue([...requiredValues, value]);
      }
      obj = { ...selectedContent, [name]: arr };
    } else {
      let nameSubject =
        nameChild === NAME_AUTOTICK_SELECT.SOCIETY
          ? NAME_AUTOTICK_SELECT.SOCIETY
          : NAME_AUTOTICK_SELECT.SCIENCE;
      let arr = selectedContent?.[nameSubject]?.[name] || [];

      if (arr.includes(value)) {
        arr = arr.filter((n) => n !== value);
        setRequiredValue(requiredValues.filter((it) => it !== value));
      } else {
        arr = [...arr, value];
        setRequiredValue([...requiredValues, value]);
      }
      obj = {
        ...selectedContent,
        [nameChild]: {
          ...selectedContent[nameChild],
          [name]: arr,
        },
      };
    }
    if (!checkSocialScience) {
      validateCheckSocialScience();
      return;
    }
    setValue("selectedContent", obj);
    setSelectedContent(obj);
  };

  const onChangeAll = (name, child) => {
    let keySubjectFilter =
      name === NAME_AUTOTICK_SELECT.SOCIETY
        ? keySelectSociety
        : keySelectScience;
    let filterChild = child.filter((item) =>
      keySubjectFilter.includes(item.name)
    );
    let arr = selectedContent?.[name] || [];
    let obj;
    if (
      name !== NAME_AUTOTICK_SELECT.SOCIETY &&
      name !== NAME_AUTOTICK_SELECT.SCIENCE
    ) {
      if (arr.length === child.length) {
        obj = { ...selectedContent, [name]: [] };
      } else {
        obj = { ...selectedContent, [name]: child };
      }
    } else {
      let valueChildSubject = convertArrChildSubjectToObject(filterChild);
      if (_.flatMap(valueChildSubject).length === _.flatMap(arr).length) {
        const resetData = _.mapValues(arr, () => []);
        obj = { ...selectedContent, [name]: resetData };
      } else {
        obj = { ...selectedContent, [name]: valueChildSubject };
      }
    }
    if (!checkSocialScience) {
      validateCheckSocialScience();
      return;
    }
    setValue("selectedContent", obj);
    setSelectedContent(obj);
  };
  const dataScience = useMemo(() => {
    const data = dataSource.find(
      (item) => item.name === NAME_AUTOTICK_SELECT.SCIENCE
    );
    const sortData = data?.child.sort((a, b) => {
      return (
        keySelectScience?.indexOf(a.name) - keySelectScience?.indexOf(b.name)
      );
    });
    return sortData;
  }, [keySelectScience]);
  const dataSociety = useMemo(() => {
    const data = dataSource.find(
      (item) => item.name === NAME_AUTOTICK_SELECT.SOCIETY
    );
    const sortData = data?.child.sort((a, b) => {
      return (
        keySelectSociety?.indexOf(a.name) - keySelectSociety?.indexOf(b.name)
      );
    });
    return sortData;
  }, [keySelectSociety]);

  
  const selectChild = (value, option, name, id, listKey) => {
    var filteredOption = option?.filter(function (item) {
      if (listKey) {
        return !listKey.includes(item.value) || item.value === value;
      }
      return option;
    });
    const lengthValueChildSelect =
      dataSubject[NAME_AUTOTICK_SELECT[name.toUpperCase()]]?.[value]?.length ||
      0;
    return (
      (Object.keys(compulsorySubjects).length > 0 && checkSocialScience ) && (
        <div className="select-content">
          <div
            className={`select-action-child ${
              lengthValueChildSelect > 5 ? "more_child_subject" : ""
            }`}
          >
            <Select
              className="child-subject"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "").includes(input)
              }
              options={filteredOption}
              notFoundContent={textNoData}
              value={selectedValues?.[name]?.[id]?.includes('empty') ? '' : selectedValues?.[name]?.[id]}
              onChange={(value) => handleSelectChange(value || `empty ${id}`, name, id)}
              allowClear={(selectedValues?.[name]?.[id]?.includes('empty') || selectedValues?.[name]?.[id]?.length === 0) ? false : true}
            />
          </div>
        </div>
      )
    );
  };
  const renderChecked = (item) => {
    if (
      item.name !== NAME_AUTOTICK_SELECT.SOCIETY &&
      item.name !== NAME_AUTOTICK_SELECT.SCIENCE
    ) {
      return selectedContent[`${item.name}`]?.length === item.child.length
        ? true
        : false;
    } else {
      let arr = selectedContent?.[item.name] || [];
      let keySubjectFilter =
        item.name === NAME_AUTOTICK_SELECT.SOCIETY
          ? keySelectSociety
          : keySelectScience;
      let filterChild = item.child.filter((item) =>
        keySubjectFilter?.includes(item.name)
      );
      let valueChildSubject = convertArrChildSubjectToObject(filterChild);
      return (
        _.flatMap(arr).length &&
        _.flatMap(valueChildSubject).length === _.flatMap(arr).length
      );
    }
  };
  return (
    <ListWrapper
      loading={loading}
      itemLayout="horizontal"
      dataSource={dataSource}
      locale={{ emptyText: t("no_data") }}
      renderItem={(item) => {
        return (
          <List.Item>
            <div className="name-courses">
              <div className="input_name_subject">
                <input
                  type="checkbox"
                  className="list-course-checked"
                  name={item.name}
                  value={item.name}
                  onChange={(e) => onChangeAll(e.target.value, item.child)}
                  checked={!!selectedContent && renderChecked(item)}
                />
                <span>{item.name}</span>
              </div>

              {item.name === NAME_AUTOTICK_SELECT.SOCIETY && (
                <div className="list-select">
                  <>
                    {Array.from({ length: 4 }, (_, id) =>
                      Object.keys(compulsorySubjects).length > 0 && selectChild(
                        keySelectSociety?.[id] || "",
                        valueSelectSociety,
                        "society",
                        id,
                        keySelectSociety
                      )
                    )}
                  </>
                </div>
              )}
              {item.name === NAME_AUTOTICK_SELECT.SCIENCE && (
                <div className="list-select">
                  <>
                    {Array.from({ length: 4 }, (_, id) =>
                      Object.keys(compulsorySubjects).length > 0 && selectChild(
                        keySelectScience?.[id] || "",
                        valueSelectScience,
                        "science",
                        id,
                        keySelectScience
                      )
                    )}
                  </>
                </div>
              )}
            </div>
            <div
              className={`list-action ${
                (item.name === NAME_AUTOTICK_SELECT.SOCIETY ||
                  item.name === NAME_AUTOTICK_SELECT.SCIENCE) &&
                  Object.keys(compulsorySubjects).length > 0
                  ? "min-h-208"
                  : "list-action"
              }`}
            >
              {typeof item.child?.[0] === "string" &&
                item.child.map((it, id) => {
                  return (
                    <label key={id} className="course-item" htmlFor="">
                      <input
                        type="checkbox"
                        className="list-course-checked"
                        name={item.name}
                        value={it}
                        id={it}
                        onChange={(e) =>
                          onChange(
                            e.target.value,
                            e.target.name,
                            id,
                            item.child
                          )
                        }
                        checked={
                          !!selectedContent &&
                          selectedContent?.[`${item.name}`]?.includes(it)
                            ? true
                            : false
                        }
                      />
                      <label
                        style={{ minWidth: "46px", marginLeft: "10px" }}
                        htmlFor=""
                      >
                        {it}
                      </label>
                    </label>
                  );
                })}
               {(item.name === NAME_AUTOTICK_SELECT.SOCIETY && Object.keys(compulsorySubjects).length > 0 ) &&  
                (
                  <div className="list_sub_content">
                    <>
                    {Array.from({ length: 4 }, (_, id) => {
                      const item = dataSociety?.find(valueItem => valueItem.name === keySelectSociety[id])
                      return (
                        // <div> {id}</div>
                        item ? (
                          <div className="sub_content"> 
                          <List.Item
                            className={`list-action-child ${
                              item.child.length > 5 ? "more_child_subject" : ""
                            } `}
                          >
                            {item.child.map((it, id) => {
                              return (
                                <label
                                  key={id}
                                  className="course-item"
                                  htmlFor=""
                                >
                                  <input
                                    type="checkbox"
                                    className="list-course-checked"
                                    name={item.name}
                                    value={it}
                                    id={it}
                                    onChange={(e) =>
                                      onChange(
                                        e.target.value,
                                        e.target.name,
                                        id,
                                        item.child,
                                        NAME_AUTOTICK_SELECT.SOCIETY
                                      )
                                    }
                                    checked={
                                      !!selectedContent &&
                                      selectedContent?.[
                                        NAME_AUTOTICK_SELECT.SOCIETY
                                      ]?.[`${item.name}`]?.includes(it)
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    style={{
                                      minWidth: "46px",
                                      marginLeft: "10px",
                                    }}
                                    htmlFor=""
                                  >
                                    {it}
                                  </label>
                                </label>
                              );
                            })}
                          </List.Item>
                        </div>
                        ) : <div className="sub_content">
                            
                            <div
                              className={`list-action-child min-h-52`}
                            >
                            </div>
                        </div>
                        )
                      }
                        
                      )}
                    </>
                  </div>
                )
              }
              {(item.name === NAME_AUTOTICK_SELECT.SCIENCE && Object.keys(compulsorySubjects).length > 0) &&  
                (
                  <div className="list_sub_content">
                    <>
                    {Array.from({ length: 4 }, (_, id) => {
                      const item = dataScience?.find(valueItem => valueItem.name === keySelectScience[id])
                      return (
                        // <div> {id}</div>
                        item ? (
                          <div className="sub_content"> 
                            <List.Item
                              className={`list-action-child ${
                                item.child.length > 5 ? "more_child_subject" : ""
                              } `}
                            >
                              {item.child.map((it, id) => {
                                return (
                                  <label
                                    key={id}
                                    className="course-item"
                                    htmlFor=""
                                  >
                                    <input
                                      type="checkbox"
                                      className="list-course-checked"
                                      name={item.name}
                                      value={it}
                                      id={it}
                                      onChange={(e) =>
                                        onChange(
                                          e.target.value,
                                          e.target.name,
                                          id,
                                          item.child,
                                          NAME_AUTOTICK_SELECT.SCIENCE
                                        )
                                      }
                                      checked={
                                        !!selectedContent &&
                                        selectedContent?.[
                                          NAME_AUTOTICK_SELECT.SCIENCE
                                        ]?.[`${item.name}`]?.includes(it)
                                          ? true
                                          : false
                                      }
                                    />
                                    <label
                                      style={{
                                        minWidth: "46px",
                                        marginLeft: "10px",
                                      }}
                                      htmlFor=""
                                    >
                                      {it}
                                    </label>
                                  </label>
                                );
                              })}
                            </List.Item>
                          </div>
                        ) : <div className="sub_content">
                            
                            <div
                              className={`list-action-child min-h-52`}
                            >
                            </div>
                        </div>
                        )
                      }
                        
                      )}
                    </>
                  </div>
                )
              }
            </div>
          </List.Item>
        );
      }}
    />
  );
}

export default ListCourses;
