import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';

const ModalWrapper = styled(Modal)`
	top: 20px;
	width: 862px !important;
	.ant-modal-content{
		padding: 35px 0px;

		.ant-modal-header{
			border-radius: 0 0 0 0 !important;
			background: linear-gradient(to bottom, #00003A 50%, #002C68 50%) !important;
			height: 54px;
			display: flex;
			justify-content: center;
			align-items: center;

			.ant-modal-title{
				color: #FFFFFF;
				font-weight: 800;
				font-size: 24px;
				line-height: 28px;
				width: 100%;
				text-align:center;
			}
		}

		.ant-modal-close{
			position: absolute;
			top: -11px;
			right: -11px;
			background-color: #f7f0f0;
			border-radius: 50%;
			width: 33px;
			height: 34px;

			&:hover{
				background-color: #fff;
			}
		}

		.ant-modal-body{
			padding: 0 24px 10px 24px !important;
		}
	}

`;

function ModalComponent({
	open,
	title,
	children,
	onOk,
	onCancel,
	...rest
}) {
	return (
		<ModalWrapper
			open={open}
			title={title}
			onOk={onOk} 
			onCancel={onCancel}
			footer={null}
			{...rest}
		>
			{children}
		</ModalWrapper>
	);
}

export default ModalComponent;
