export default function IconBack() {
  return (
    <>
      <svg
        fill="#7d0006"
        version="1.1"
        baseProfile="tiny"
        id="Layer_1"
        width="20px"
        height="20px"
        viewBox="0 0 42 42"
      >
        <polygon
          fillRule="evenodd"
          points="27.066,1 7,21.068 26.568,40.637 31.502,35.704 16.865,21.068 32,5.933 "
        />
      </svg>
    </>
  );
}
