import { notification } from 'antd';
import i18next from 'i18next';

i18next.loadNamespaces(['common']);

class Notification {
  static success(message, time = 3000) {
    notification.success({
        message: i18next.t("common:status.success"),
        description: message,
      }
    , time)
  }

  static warning(message, time = 3000) {
    notification.warning({
      message: i18next.t("common:status.warning"),
      description: message,
    }, time)
  }

  static error(message, time = 3000) {
    notification.error({
      message: i18next.t("common:status.error"),
      description: message,
    }, time)
  }
}
export default Notification